
const storedData = localStorage.getItem('userData');
const userDetails = storedData ? JSON.parse(storedData) : {};
export const INTERMEDIARY_ID = userDetails.entityRoleId || null;

export enum SummaryKeyInvestments {
  Payment_Out = 'bankPayment',
  Receipt_In = 'bankReceipt',
  Face_Value = 'faceValue',
  Commission_Amount = 'commissionAmount',
  Payment_Amount = 'paymentAmount',
  Buy_Rate = 'buyRate',
  Commission_Rate = 'commissionRate',
  Commission = 'commission',
  Months = 'months',
  Balance = 'balance',
  Principal = 'principal',
  Yield = 'yield',
  Date = 'date',
}

export enum SummaryKeyFxrate {
  BuyRate = 'buyRate',
  Gbp = 'gbp',
  Usd = 'usd',
  Eonia = 'eonia',
  Libor = 'libor',
  Obfr = 'obfr',
}

export enum SummaryKeyTradeTransactions {
  PTV = 'ptv',
  SellRate = 'sellRate',
  EDSO = 'edso',
  RDSO = 'rdso',
  ADSO = 'adso',
  Months = 'months',
}

export enum SummaryKeyBankDetails {
  bankId = 'bankId',
  bankName = 'bankName',
  sortCode = 'sortCode',
  account = 'account',
  address = 'address',
  swift = 'swift',
  iban = 'iban',
}
export enum SummaryKeyFinancialStatements {
  Payment_Out = 'bankPayment',
  Receipt_In = 'bankReceipt',
  Face_Value = 'faceValue',
  Buy_Rate = 'buyRate',
  Months = 'months',
}

export enum InvestorTypeEnum {
  Organization = 1,
  Person = 2,
}
