import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth.gaurd';

export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'component',
        loadChildren: () => import('./component/component.module').then((m) => m.ComponentsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'profile',
        loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'bank-details',
        loadChildren: () => import('./bank-details/bank-details.module').then((m) => m.BankDetailsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'commission-statement',
        loadChildren: () => import('./commission-statement/commission-statement.module').then((m) => m.CommissionStatementModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'investor-statements',
        loadChildren: () => import('./investor-statement/investor-statement.module').then((m) => m.InvestorStatementModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'investment',
        loadChildren: () => import('./investments/investment.module').then((m) => m.InvestmentModule),
        canActivate: [AuthGuard],
      },
      {
        path: '',
        loadChildren: () => import('./investors/investors.module').then((m) => m.InvestorsModule),
        canActivate: [AuthGuard],
      },
      {
        path: '',
        loadChildren: () => import('./withdraw-funds/withdrawFunds.module').then((m) => m.WithdrawFundsModule),
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '**',
    redirectTo: '/dashboard',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(Approutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

