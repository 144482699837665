<ag-grid-angular
    id="myGrid"
    [class]="'ag-theme-quartz container-grid common-grids ' + classNames"
    [rowData]="gridRowData"
    [components]="components"
    [columnDefs]="gridColDefs"
    [defaultColDef]="gridDefaultColDef"
    [rowSelection]="'multiple'"
    [getContextMenuItems]="getContextMenuItems"
    [pagination]="hasPagination"
    [paginationPageSize]="gridPaginationPageSize"
    [detailRowAutoHeight]="true"
    [enableRangeSelection]="true"
    [copyHeadersToClipboard]="false"
    [ensureDomOrder]="true"
    [copyGroupHeadersToClipboard]="true"
    [suppressCopyRowsToClipboard]="true"
    [suppressCopySingleCellRanges]="true"
    [multiSortKey]="'ctrl'"
    [sideBar]="sideBar"
    [getRowId]="getRowId"
    [editType]="editType"
    [domLayout]="domLayout"
    [rowDragManaged]="isGridDraggable"
    (gridReady)="gridReady.emit($event)"
    (filterChanged)="filterChanged.emit($event)"
    (sortChanged)="sortChanged.emit($event)"
    (rowClicked)="rowClicked.emit($event)"
    [masterDetail]="hasMasterDetail"
    [detailCellRendererParams]="detailCellRendererParams"
    [keepDetailRows]="keepDetailRows"
    [keepDetailRowsCount]="keepDetailRowsCount"
    [rowHeight]="rowHeight"
    (cellValueChanged)="cellValueChanged.emit($event)"
    (cellClicked)="cellClicked.emit($event)"
    (rowValueChanged)="rowValueChanged.emit($event)"
    (rowDragEnd)="rowDragEnd.emit($event)"
    (rowDragEnter)="rowDragEnter.emit($event)"
    (rowDragMove)="rowDragMove.emit($event)"
    [suppressMoveWhenRowDragging]="true"
    (columnVisible)="columnVisible.emit($event)"
    [stopEditingWhenCellsLoseFocus]="true"
    (rowDoubleClicked)="rowDoubleClicked.emit($event)"
    [processCellForClipboard]="processCellForClipboard"
/>
