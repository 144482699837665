import { HttpService } from '../../../services/http.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../../environments/environment';
import { Summary } from '../../../shared/interfaces/bank-response.interface';
import { InvestorsQueryParams } from 'src/app/shared/interfaces/investors-query-params';
import { InvestorsByIdResponseInterface, InvestorsResponseInterface } from 'src/app/shared/interfaces/Investors-response.interface';
import { InvestorInterface, InvestorsDistinctFilterValuesInterface } from '../interfaces/Investors-distinct-filter-values.interface';

@Injectable({ providedIn: 'root' })
export class InvestorsHttpService extends HttpService {
  getInvestorsFilterData(): Observable<InvestorsDistinctFilterValuesInterface> {
    return this.http.get<InvestorsDistinctFilterValuesInterface>(`${this.baseUrl}Investors/distinct-filter-values`);
  }

  getCurrencyFilterData(): Observable<string[]> {
    return this.http.get<string[]>(`${this.baseUrl}currencies`);
  }

  getInvestorsData(params: Partial<InvestorsQueryParams>): Observable<InvestorsResponseInterface> {
    const baseUrl = environment.url + 'Investors';
    return this.http.get<InvestorsResponseInterface>(baseUrl, {
      params: this.queryCleaner(params),
    });
  }

  getInvestorsSummary(params: Partial<InvestorsQueryParams>): Observable<Summary> {
    return this.http.get<Summary>(`${environment.url}Investors/summary`, {
      params: this.queryCleaner(params),
    });
  }

  addInvestor(investorData: InvestorInterface): Observable<any> {
    return this.http.post(`${this.intermediaryBaseUrl}investors`, investorData, { headers: this.getHeaders() });
  }

  getInvestorById(id: string): Observable<any> {
    return this.http.get<InvestorsByIdResponseInterface>(`${this.intermediaryBaseUrl}investors/${id}`, { headers: this.getHeaders() });
  }
}
