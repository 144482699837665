import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { getFormData } from 'src/app/shared/helpers/utils';
import { Investment } from 'src/app/shared/interfaces/investments-response.interface';
import Swal from 'sweetalert2';
import { InvestmentsHttpService } from '../../common/services/investments-http.service';
import { InvestmentsFormProvider } from '../../investments-form-provider';
import { NgbActiveModal, NgbDate, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from 'src/app/services/http.service';
import * as moment from 'moment';
import { INTERMEDIARY_ID } from 'src/app/shared/enums/common.enum';

@Component({
  selector: 'app-add-edit-investments',
  templateUrl: './add-edit-investments.component.html',
  styleUrls: ['./add-edit-investments.component.scss'],
})
export class AddEditInvestmentsComponent {
  investmentForm: FormGroup;
  // todo need remove when can get from backend side
  tooltipData = {
    ccy: 'Currency',
    faceValue: 'Face Value',
    comments: 'Comments',
    months: 'Months',
    type: 'Type',
    investorId: 'Investor',
    transactionType: 'Transaction Type',
    date: 'Date',
    product: 'Product',
  };
  isSubmitted = false;
  type: { id: string; name: string }[];
  investors: { id: string; name: string }[];
  allCcyData: { id: string; name: string }[];
  transactionType: { id: string; name: string }[];
  product: { id: string; name: string }[];
  investmentData: Investment;
  allCurrencyBankAccountData: any = [];
  intermediaryId: string = INTERMEDIARY_ID;
  constructor(
    private investmentsHttpService: InvestmentsHttpService,
    private investmentsFormProvider: InvestmentsFormProvider,
    public activeModal: NgbActiveModal,
    private http: HttpService,
    private config: NgbDatepickerConfig,
  ) {
    this.initDatePickerConfig();
    this.initializeIntermediaryId();
  }

  ngOnInit(): void {
    this.investmentForm = this.investmentsFormProvider.initInvestmentForm(this.investmentData);
    this.getDropDownsData();
  }

  initializeIntermediaryId(): void {
    const storedData = localStorage.getItem('userData');
    const userDetails = storedData ? JSON.parse(storedData) : {};
    this.intermediaryId = userDetails.entityRoleId || null;
  }

  onSelect(event: any): void {
    this.isSubmitted = false;
  }

  onSubmit() {
    const fieldsToFormat = ['date'];
    this.isSubmitted = true;
    if (this.investmentForm.invalid) {
      return;
    }
    let rawData = this.investmentForm.value;
    rawData = getFormData(rawData);
    fieldsToFormat.forEach((field) => {
      const date = rawData[field];
      if (date) {
        rawData[field] = field === 'months' ? moment(date).format('MM') : moment(`${date.year}-${date.month}-${date.day}`, 'YYYY-MM-DD').format('YYYY-MM-DD');
      }
    });
    rawData.intermediaryId = this.intermediaryId.toString();
    rawData.investorId = rawData.investorId.toString();
    rawData.months = Number(rawData.months);
    rawData.faceValue = Number(rawData.faceValue.replace(/,/g, ''));
    this.doCreate(rawData);
  }

  doCreate(data: any): void {
    this.investmentsHttpService.postData('investments', data).subscribe({
      next: (response: any) => {
        this.closeModal();
        this.investmentForm.reset();
        Swal.fire({
          icon: 'success',
          text: 'Investment successfully added.',
          showConfirmButton: false,
          timer: 5000,
        });
      },
      error: (error) => {
        Swal.fire({
          icon: 'error',
          text: error.error.details || error.error.message,
          showConfirmButton: true,
          confirmButtonText: 'Ok',
        });
      },
    });
  }

  private getDropDownsData(): void {
    this.investmentsHttpService.getInvestmentsFilterData().subscribe((investmentsFilterData) => {
      investmentsFilterData.types.sort((a, b) => a.localeCompare(b));
      this.type = [
        ...(investmentsFilterData.types.map((type) => ({
          id: type,
          name: type,
        })) || []),
      ];
      this.transactionType = [
        ...(investmentsFilterData.transactionTypes.map((transactionType) => ({
          id: transactionType,
          name: transactionType,
        })) || []),
      ];

      this.product = [
        ...(investmentsFilterData.products.map((product) => ({
          id: product,
          name: product,
        })) || []),
      ];
    });

    this.http.getCurrencyWithBank().subscribe((response: any) => {
      this.allCcyData = [
        ...(response.map((ccy: any) => ({
          id: ccy.currency,
          name: ccy.currency,
        })) || []),
      ];
    });

    this.investmentsHttpService.getInvestorsFilterData().subscribe((investor: any) => {
      this.investors = [
        ...(investor.items
          .sort((a: any, b: any) => {
            const nameA = a.entity.name.toUpperCase();
            const nameB = b.entity.name.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          })
          .map((investor: any) => ({
            id: investor.id,
            name: `${investor.id}- ${investor.entity.name}`,
          })) || []),
      ];
    });
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  private getCurrentDate(): NgbDate {
    const today = new Date();
    return new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate());
  }

  initDatePickerConfig() {
    let currentDate = this.getCurrentDate();
    this.config.minDate = { year: 1970, month: 1, day: 1 };
    this.config.maxDate = currentDate;
  }
}
