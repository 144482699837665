import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { getFormData } from 'src/app/shared/helpers/utils';
import { NgbActiveModal, NgbDate, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from 'src/app/services/http.service';
import { WithdrawFundsFormProvider } from '../../withdrawFunds-form-provider';
import { WithdrawFundsHttpService } from '../../common/services/withdrawFunds-http.service';
import { WithdrawFund } from 'src/app/shared/interfaces/withdraw-fund-response.interface';
import { InvestmentsHttpService } from 'src/app/investments/common/services/investments-http.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { INTERMEDIARY_ID } from 'src/app/shared/enums/common.enum';
import { InvestorsHttpService } from 'src/app/investors/common/services/Investors-http.service';
import { InvestorsByIdResponseInterface } from 'src/app/shared/interfaces/Investors-response.interface';

@Component({
  selector: 'app-withdraw-funds',
  templateUrl: './withdraw-funds.component.html',
  styleUrls: ['./withdraw-funds.component.scss'],
})
export class WithdrawFundsComponent {
  WithdrawFundForm: FormGroup;
  // todo need remove when can get from backend side
  tooltipData = {
    type: 'Type',
    ccy: 'Currency',
    amount: 'Amount',
    note: 'Note',
    investorId: 'Investor',
    faceValue: 'Face Value',
    date: 'Date',
    product: 'Product',
  };
  isSubmitted = false;
  noteText: string = 'The above funds will be remitted to:';
  iban: string = 'IBAN';
  type: { id: string; name: string }[];
  WithdrawFundData: WithdrawFund;
  allCurrencyBankAccountData: any = [];
  investors: { id: string; name: string }[];
  product: { id: string; name: string }[];
  intermediaryId= INTERMEDIARY_ID;
  constructor(
    private withdrawFundsHttpService: WithdrawFundsHttpService,
    private investmentsHttpService: InvestmentsHttpService,
    private investorsHttpService: InvestorsHttpService,
    private withdrawFundsFormProvider: WithdrawFundsFormProvider,
    public activeModal: NgbActiveModal,
    private config: NgbDatepickerConfig,
  ) {
    this.initDatePickerConfig();
    this.initializeIntermediaryId();
  }

  ngOnInit(): void {
    this.WithdrawFundForm = this.withdrawFundsFormProvider.initWithdrawFundsForm(this.WithdrawFundData);
    this.getDropDownsData();
  }
  
  initializeIntermediaryId(): void {
    const storedData = localStorage.getItem('userData');
    const userDetails = storedData ? JSON.parse(storedData) : {};
    this.intermediaryId = userDetails.entityRoleId || null;
  }

  onSubmit() {
    const fieldsToFormat = ['date'];
    this.isSubmitted = true;
    if (this.WithdrawFundForm.invalid) {
      return;
    }
    let rawData = this.WithdrawFundForm.value;
    rawData = getFormData(rawData);
    fieldsToFormat.forEach((field) => {
      const date = rawData[field];
      if (date) {
        rawData[field] = field === 'months' ? moment(date).format('MM') : moment(`${date.year}-${date.month}-${date.day}`, 'YYYY-MM-DD').format('YYYY-MM-DD');
      }
    });
    rawData.intermediaryId = this.intermediaryId.toString();
    rawData.investorId= rawData.investorId.toString();
    rawData.faceValue = Number(rawData.faceValue.replace(/,/g, ''));
    this.doCreate(rawData);
  }

  onSelect(event: { id: string; name: string }): void {
    if (event?.id) {
      this.getInvestorById(event.id);
    } else {
      this.iban = 'IBAN';
    }
  }

  doCreate(data: any): void {
    this.investmentsHttpService.postData('investments', data).subscribe({
      next: (response: any) => {
        this.closeModal();
        this.WithdrawFundForm.reset();
        Swal.fire({
          icon: 'success',
          text: 'Investment successfully added.',
          showConfirmButton: false,
          timer: 5000,
        });
      },
      error: (error) => {
        Swal.fire({
          icon: 'error',
          text: error.error.details || error.error.message,
          showConfirmButton: true,
          confirmButtonText: 'Ok',
        });
      },
    });
  }

  private getDropDownsData(): void {
    this.withdrawFundsHttpService.getFilterData().subscribe((investmentsFilterData) => {
      investmentsFilterData.types.sort((a, b) => a.localeCompare(b));
      this.type = [
        ...(investmentsFilterData.types.map((type) => ({
          id: type,
          name: type,
        })) || []),
      ];

      this.product = [
        ...(investmentsFilterData.products.map((product) => ({
          id: product,
          name: product,
        })) || []),
      ];

      this.investmentsHttpService.getInvestorsFilterData().subscribe((investor: any) => {
        this.investors = [
          ...(investor.items
            .sort((a: any, b: any) => {
              const nameA = a.entity.name.toUpperCase();
              const nameB = b.entity.name.toUpperCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            })
            .map((investor: any) => ({
              id: investor.id,
              name: `${investor.id}- ${investor.entity.name}` ,
            })) || []),
        ];
      });
    });
  }

  getInvestorById(id: string) {
    this.investorsHttpService.getInvestorById(id).subscribe({
      next: (response: InvestorsByIdResponseInterface) => {
        if (response.bankAccounts && response.bankAccounts.length > 0) {
          this.iban = response.bankAccounts[0].iban;
        } else {
          this.iban = 'IBAN';
          Swal.fire({
            icon: 'error',
            text: 'No IBAN found for the investor.',
            showConfirmButton: true,
            confirmButtonText: 'Ok',
          });
        }
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  private getCurrentDate(): NgbDate {
    const today = new Date();
    return new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate());
  }

  initDatePickerConfig() {
    let currentDate = this.getCurrentDate();
    this.config.minDate = { year: 1970, month: 1, day: 1 };
    this.config.maxDate = currentDate;
  }
}
