import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InvestorsHttpService } from '../../common/services/Investors-http.service';
import { InvestorsFormProvider } from '../../Investors-form-provider';
import { HttpService } from 'src/app/services/http.service';
import { NgbActiveModal, NgbDate, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { COUNTRY_LIST } from 'src/app/shared/constants/countryList.const';
import { getFormData } from 'src/app/shared/helpers/utils';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { INTERMEDIARY_ID, InvestorTypeEnum } from 'src/app/shared/enums/common.enum';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs';

@Component({
  selector: 'app-add-edit-investor',
  templateUrl: './add-edit-investor.component.html',
  styleUrls: ['./add-edit-investor.component.scss'],
})
export class AddEditInvestorComponent {
  investorForm: any = FormGroup;
  // todo need remove when can get from backend side
  tooltipData = {
    firstName: 'First Name',
    middleName: 'Middle Name',
    lastName: 'Last Name',
    email: 'Email',
    dob: 'Date Of Birth',
    address: 'Address',
    postCode: 'Post Code',
    city: 'City',
    state: 'County',
    country: 'Country',
    homePNumber: 'Home Phone',
    officePNumber: 'Office Phone',
    mobileNumber: 'Mobile Phone',
    area: 'Area',
    swift: 'SWIFT BIC',
    iban: 'IBAN',
    sortCode: 'Sort Code',
    account: 'Account',
    organization: 'Organization',
  };
  isEditMode: boolean = true;
  isPeopleProfile: boolean = true;
  radioItems: Array<string> = ['Online', 'Manually'];
  savings: Array<string> = ['Deposit', 'Pension'];
  model = { option: 'Online' };
  savingModel = { option: 'Deposit' };
  allCcyData: { id: string; name: string }[];
  countryList: { id: string; name: string; countryCode: string }[];
  intermediaryId: number = INTERMEDIARY_ID;
  toggleSettings = {
    labels: {
      unchecked: 'Person',
      checked: 'Organization',
    },
    values: {
      unchecked: 'person',
      checked: 'organization',
    },
    switchColor: {
      checked: '#fff',
      unchecked: '#fff',
    },
    color: {
      unchecked: '#d4d5db',
      checked: '#d4d5db',
    },
    fontColor: {
      checked: 'black',
      unchecked: 'black',
    },
  };
  isPerson: boolean = true;
  isIbanRequired: boolean = true;
  isAccountRequired: boolean = true;
  isSortCodeRequired: boolean = true;
  type: number = InvestorTypeEnum.Person;
  constructor(
    private http: HttpService,
    private investorsHttpService: InvestorsHttpService,
    private investorsFormProvider: InvestorsFormProvider,
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private config: NgbDatepickerConfig,
  ) {
    this.initializeIntermediaryId();
    this.initDatePickerConfig();
  }

  ngOnInit(): void {
    this.addBodyClass();
    this.investorForm = this.investorsFormProvider.initInvestorsForm();
    this.getDropDownsData();
    this.initDatePickerConfig();
    this.subscribeToSortCodeValueChange();
    this.subscribeToValueChanges();
    this.updateValidationOnSubscribe();
  }

  initializeIntermediaryId(): void {
    const storedData = localStorage.getItem('userData');
    const userDetails = storedData ? JSON.parse(storedData) : {};
    this.intermediaryId = userDetails.entityRoleId || null;
  }

  onSubmit() {
    this.isEditMode = false;
    if (this.investorForm.invalid) {
      return;
    }
    let rawData = this.investorForm.value;
    rawData = getFormData(rawData);
    if (!!rawData.birthDate) {
      const { day, month, year } = rawData.birthDate;
      rawData.birthDate = moment(`${year}-${month}-${day}`, 'YYYY-M-D').format('YYYY-MM-DD');
    }
    const apiData = this.createApiData(rawData);
    if (!this.isPerson) apiData.lastName = '';
    this.doCreate(apiData);
  }

  doCreate(data: any): void {
    this.investorsHttpService.addInvestor(data).subscribe({
      next: (response: any) => {
        this.investorForm.reset();
        this.closeModal();
        Swal.fire({
          icon: 'success',
          text: 'Investor successfully added.',
          showConfirmButton: false,
          timer: 3000,
        });
      },
      error: (error) => {
        const errorMessages = error.error.message;
        const containsBicError = errorMessages.includes('bankAccount.swiftBic must be a BIC or SWIFT code');
        const containsIbanError = errorMessages.includes('bankAccount.iban must be an IBAN');
        const userFriendlyMessage = containsBicError || containsIbanError ? 'Error' : errorMessages.join(', ');
        Swal.fire({
          icon: 'error',
          text: userFriendlyMessage,
          showConfirmButton: true,
          confirmButtonText: 'Ok',
        });
      },
    });
  }

  createApiData(rawData: any): any {
    const data = {
      intermediaryId: this.intermediaryId.toString() || '',
      firstName: rawData.firstName || '',
      lastName: rawData.lastName || '',
      email: rawData.email || '',
      birthDate: rawData.birthDate || '',
      address: {
        country: rawData.country || '',
        countryCode: rawData.countryCode || 'IR',
        state: rawData.state || '',
        city: rawData.city || '',
        area: rawData.area || '',
        lineOne: rawData.lineOne || '',
        lineTwo: rawData.lineTwo || '',
        zipCode: rawData.zipCode ? rawData.zipCode?.toString() : '',
      },
      phoneNumbers: {
        home: rawData.home || '',
        office: rawData.office || '',
        mobile: rawData.mobile || '',
      },
      bankAccount: {
        bankId: Number(rawData.bankId) || '',
        name: rawData.name || '',
        swiftBic: rawData.swiftBic || '',
        sortCode: rawData.sortCode?.toString() || '',
        account: rawData.account || '',
        iban: rawData.iban || '',
      },
      type: this.type,
    };
    return this.removeEmpty(data);
  }

  private getDropDownsData(): void {
    this.countryList = COUNTRY_LIST;
  }

  get errorControl() {
    return this.investorForm.controls;
  }

  getFormControlValue(controlName: string): any {
    const control = this.investorForm.get(controlName);
    return control ? control.value : null;
  }

  get additionalNames(): FormArray {
    return this.investorForm.get('additionalNames') as FormArray;
  }

  addAdditionalName(): void {
    this.additionalNames.push(
      this.fb.group({
        additionalFirstName: [''],
        additionalLastName: [''],
      }),
    );
  }

  removeAdditionalName(index: number): void {
    this.additionalNames.removeAt(index);
  }

  onSelectCountry(event: any): void {
    const selectedCountry = event.target.value;
    const country = this.countryList.find((c) => c.name === selectedCountry);
    this.investorForm.patchValue({ countryCode: country ? country.countryCode : '' });
  }

  addBodyClass() {
    const bodyTag = document.body;
    bodyTag.classList.add('add-investor');
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  private getCurrentDate(): NgbDate {
    const today = new Date();
    return new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate());
  }

  initDatePickerConfig() {
    let currentDate = this.getCurrentDate();
    this.config.minDate = { year: 1910, month: 1, day: 1 };
    this.config.maxDate = currentDate;
  }

  resetDatePickerConfig() {
    this.config.minDate = { year: 1, month: 1, day: 1 };
    this.config.maxDate = { year: 9999, month: 12, day: 31 };
  }

  private subscribeToSortCodeValueChange() {
    this.investorForm
      .get('sortCode')
      ?.valueChanges.pipe(
        distinctUntilChanged(),
        filter((data) => !!data),
      )
      .subscribe((value: string) => {
        const sanitizedValue = value.replace(/[^0-9\-]/g, '');
        const formattedValue = this.formatSortCode(sanitizedValue);
        this.investorForm.get('sortCode')?.patchValue(formattedValue, { emitEvent: false });
      });
  }

  private subscribeToValueChanges() {
    const sortCodeControl = this.investorForm.get('sortCode');
    const accountControl = this.investorForm.get('account');
    const ibanControl = this.investorForm.get('iban');
    sortCodeControl?.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(() => this.updateValidationOnSubscribe());
    accountControl?.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(() => this.updateValidationOnSubscribe());
    ibanControl?.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(() => this.updateValidationOnSubscribe());
  }

  private updateValidationOnSubscribe() {
    const sortCodeControl = this.investorForm.get('sortCode');
    const accountControl = this.investorForm.get('account');
    const ibanControl = this.investorForm.get('iban');
    const sortCodeValue = sortCodeControl?.value;
    const accountValue = accountControl?.value;
    const ibanValue = ibanControl?.value;
    if (ibanValue) {
      sortCodeControl?.clearValidators();
      accountControl?.clearValidators();
      ibanControl?.setValidators([Validators.required]);
      this.isIbanRequired = true;
      this.isSortCodeRequired = false;
      this.isAccountRequired = false;
    } else if (sortCodeValue || accountValue) {
      if (sortCodeValue && accountValue) {
        this.isIbanRequired = false;
        this.isSortCodeRequired = true;
        this.isAccountRequired = true;
      } else {
        this.isIbanRequired = true;
      }
      ibanControl?.clearValidators();
      sortCodeControl?.setValidators([Validators.maxLength(14), Validators.required]);
      accountControl?.setValidators([Validators.required]);
    } else {
      sortCodeControl?.setValidators([Validators.maxLength(14), Validators.required]);
      accountControl?.setValidators([Validators.required]);
      ibanControl?.setValidators([Validators.required]);
      this.isIbanRequired = true;
      this.isSortCodeRequired = true;
      this.isAccountRequired = true;
    }
    sortCodeControl?.updateValueAndValidity({ emitEvent: false });
    accountControl?.updateValueAndValidity({ emitEvent: false });
    ibanControl?.updateValueAndValidity({ emitEvent: false });
  }

  private formatSortCode(value: string): string {
    return value.replace(/(\d{2})(?=\d)/g, '$1-');
  }

  removeEmpty(obj: any): any {
    const result = Object.fromEntries(
      Object.entries(obj)
        .filter(([_, v]) => v !== null && v !== '')
        .map(([k, v]) => [k, v && typeof v === 'object' ? this.removeEmpty(v) : v]),
    );
    // Remove object if it's empty
    for (const key in result) {
      if (result[key] && typeof result[key] === 'object' && Object.keys(result[key]).length === 0) {
        delete result[key];
      }
    }

    return result;
  }

  toggleChange(event: any): void {
    this.isPerson = event === 'person';
    const firstNameControl = this.investorForm.get('firstName');
    const lastNameControl = this.investorForm.get('lastName');
    if (this.isPerson) {
      firstNameControl?.setValidators([Validators.required]);
      lastNameControl?.setValidators([Validators.required]);
      this.type = InvestorTypeEnum.Person;
    } else {
      this.type = InvestorTypeEnum.Organization;
      lastNameControl?.clearValidators();
    }
    firstNameControl?.updateValueAndValidity();
    lastNameControl?.updateValueAndValidity();
  }

  ngOnDestroy() {
    const bodyTag = document.body;
    bodyTag.classList.remove('add-investor');
    this.resetDatePickerConfig();
  }
}

