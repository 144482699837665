import { Injectable } from '@angular/core';
import { HttpService } from '../services/http.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProfileDetails } from '../shared/interfaces/profileDetails-response.interface';

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends HttpService {
  changePassword(credentials: { oldPassword: string; newPassword: string; confirmNewPassword: string }): Observable<any> {
    return this.http.post(`${this.intermediaryBaseUrl}auth/change-password`, credentials, { headers: this.getHeaders() });
  }

  getUserProfileData(): Observable<any> {
    return this.http.get(`${this.intermediaryBaseUrl}users/profile`, { headers: this.getHeaders() });
  }
  updateUserProfile(profileData: ProfileDetails): Observable<any> {
    return this.http.patch(`${this.intermediaryBaseUrl}users/profile`, profileData, { headers: this.getHeaders() });
  }
}
