import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class InvestmentsFormProvider {
  investmentForm: FormGroup;

  constructor(private fb: FormBuilder) {}

  initInvestmentForm(data?: any): FormGroup {
    return (this.investmentForm = this.fb.group({
      investorId: [data?.investorId, [Validators.required]],
      ccy: [data?.ccy || ''],
      faceValue: [+data?.faceValue || '', [Validators.required, Validators.pattern('^[0-9,.]*$')]],
      months: [data?.months, [Validators.pattern('^[0-9]*$')]],
      type: [data?.type || '', [Validators.required]],
      comments: [data?.comments],
      transactionType: ['Receipt'],
      product: [data?.product || '', [Validators.required]],
      date: [data?.date || null, [Validators.required]],
    }));
  }
}
