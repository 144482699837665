import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InvestorTypeEnum } from '../shared/enums/common.enum';

@Injectable({ providedIn: 'root' })
export class InvestorsFormProvider {
  investorsForm: FormGroup;

  constructor(private fb: FormBuilder) {}

  initInvestorsForm(data?: any): FormGroup {
    return this.fb.group({
      intermediaryId: [data?.intermediaryId],
      firstName: [data?.firstName, [Validators.required]],
      lastName: [data?.lastName, [Validators.required]],
      email: [data?.email, [Validators.email]],
      birthDate: [data?.birthDate],
      home: [data?.phoneNumbers?.home, [Validators.maxLength(15)]],
      office: [data?.phoneNumbers?.office, [Validators.maxLength(15)]],
      mobile: [data?.phoneNumbers?.mobile, [Validators.maxLength(15)]],
      country: [data?.address?.country || '', [Validators.required]],
      countryCode: [data?.address?.countryCode],
      state: [data?.address?.state, [Validators.required]],
      city: [data?.address?.city, [Validators.required]],
      area: [data?.address?.area],
      lineOne: [data?.address?.lineOne, [Validators.required]],
      lineTwo: [data?.address?.lineTwo],
      zipCode: [data?.address?.zipCode],
      amount: [data?.amount],
      quotedRate: [data?.quotedRate],
      trustee: [data?.trustee || ''],
      savings: [data?.savings],
      sortCode: [data?.sortCode || '', [Validators.maxLength(14), Validators.required]],
      account: [data?.account || '', [Validators.required]],
      swiftBic: [data?.swiftBic || ''],
      iban: ['', [Validators.required]],
      type: [data?.type || InvestorTypeEnum.Person],
    });
  }
}
