<div 
     class="modal fade show custom_popup custom_popup_responsive justify-content-center"
     tabindex="-1"
     role="dialog"
>
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header justify-content-between">
                <h5 class="modal-title">
                    Withdrawals
                </h5>
                <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        (click)="closeModal()"
                >
                    <span>&times;</span>
                </button>
            </div>
            <div class="modal-body padding-model form-editing">
              
<div class="row">
    <div class="col-lg-12">
        <div class="card w-100">
            <div class="card">
                <form
                    *ngIf="WithdrawFundForm"
                    [formGroup]="WithdrawFundForm"
                    (ngSubmit)="onSubmit()"
                >
                    <div class="row">

                        <div style="width: 152px;" class="field-groups">
                            <div class="mt-3">
                                <label for="investorId" class="form-title">
                                    Investor
                                    <span
                                        class="tooltip-ng ms-0"
                                        placement="bottom"
                                        ngbTooltip="{{ tooltipData.investorId }}">
                                        <i class="bi bi-question-circle"></i>
                                    </span>
                                </label>
                                <ng-select class="custom"
                                           id="investorId"
                                           [style.flex]="1"
                                           [items]="investors"
                                           bindLabel="name"
                                           bindValue="id"
                                           placeholder="All"
                                           formControlName="investorId"
                                           (change)="onSelect($event)"
                                           >
                                </ng-select>
                            </div>
                            <div *ngIf="isSubmitted && WithdrawFundForm.controls['investorId'].invalid">
                                <small class="text-danger" *ngIf="WithdrawFundForm.controls['investorId'].errors?.['required']">
                                    <label class="error">
                                        <img src="assets/images/icon/error.png" class="error-icon">
                                        Data is Required.
                                    </label>
                                </small>
                              </div>

                        </div>

                        <div style="width: 57px;"  class="field-groups custom-margin-1 align-items-center daterange_sec select_bank_filter">
                            <div class="mt-3">
                                <label for="type" class="form-title"> Type<span
                                    class="tooltip-ng"
                                    placement="bottom"
                                    ngbTooltip="{{ tooltipData.type }}"
                                ><i class="bi bi-question-circle"></i></span
                                ></label>
                                <select
                                    class="custom-form-select border-radius-100"
                                    id="type"
                                    formControlName="type"
                                >
                                    <option *ngFor="let tt of type" [value]="tt.id">
                                        {{ tt.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                      

                        <div style="width: 86px;" class="field-groups">
                            <div class="mt-3">
                                <label for="faceValue" class="form-title"
                                > Amount
                                    <span
                                        class="tooltip-ng ms-0"
                                        placement="bottom"
                                        ngbTooltip="{{ tooltipData.amount }}"
                                    ><i class="bi bi-question-circle"></i></span
                                    ></label>
                                <input
                                    id="faceValue"
                                    onlyNumber
                                    type="text"
                                    class="form-control ft-width numberText"
                                    placeholder=""
                                    formControlName="faceValue"
                                    maxlength="13"
                                    NumberWithCommas
                                />
                            </div>
                        </div>

                        <div style="width: 57px;" class="field-groups">
                            <div class="mt-3">
                                <label for="product" class="form-title text-nowrap"
                                >Product<span
                                    class="tooltip-ng"
                                    placement="bottom"
                                    ngbTooltip="{{ tooltipData.product }}"
                                ><i class="bi bi-question-circle"></i></span
                                ></label>
                                <select
                                    class="custom-form-select border-radius-100"
                                    id="product"
                                    formControlName="product"
                                >
                                    <option *ngFor="let prod of product" [value]="prod.id">
                                        {{ prod.name }}
                                    </option>
                                </select>
                            </div>  
                        </div>

                        <div style="width: 135px;" class="field-groups">
                            <div class="mt-3">
                                <label for="date" class="form-title"> Date
                                    <span
                                        class="tooltip-ng ms-0"
                                        placement="bottom"
                                        ngbTooltip="{{ tooltipData.date }}">
                                        <i class="bi bi-question-circle"></i>
                                    </span>
                                </label>
                                <div class="input-group">
                                    <input
                                        id="date"
                                        class="form-control"
                                        placeholder="YYYY-MM-DD"
                                        name="d3"
                                        formControlName="date"
                                        ngbDatepicker
                                        #d3="ngbDatepicker"
                                    />
                                    <button
                                        class="btn btn-outline-secondary btn-calendar-icon"
                                        (click)="d3.toggle()"
                                        type="button"
                                    >
                                        <img src="./assets/images/icon/calendar-icon.svg" />
                                    </button>
                                </div>
                            </div> 
                        </div>


                        <div class="col-md-12">
                            <div class="mt-3 text-nowrap">  
                            {{noteText}} {{iban}}
                            </div>
                        </div>
                        <div class="col-12 bottom-fixed">
                            <hr />
                            <div class="d-flex justify-content-center mt-3">
                                <button [disabled]="WithdrawFundForm.invalid" type="submit" class="btn btn-light me-3 btn-custom-footer save_btn">
                                    Save
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-light btn-custom-footer cancel_btn"
                                    data-dismiss="modal"
                                    (click)="closeModal()"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
            </div>
        </div>
    </div>
</div>
