import { HttpService } from '../../../services/http.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { WithdrawFundsDistinctFilterValuesInterface } from '../interfaces/withdrawFunds-distinct-filter-values.interface';

@Injectable({ providedIn: 'root' })
export class WithdrawFundsHttpService extends HttpService {
  getFilterData(): Observable<WithdrawFundsDistinctFilterValuesInterface> {
    return this.http.get<WithdrawFundsDistinctFilterValuesInterface>(`${this.intermediaryBaseUrl}investments/distinct-filter-values`);
  }

  getCurrencyFilterData(): Observable<string[]> {
    return this.http.get<string[]>(`${this.baseUrl}currencies`);
  }
}
