import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private intermediaryUrl = environment.intermediaryUrl;
  private jwtHelper = new JwtHelperService();
  constructor(
    private http: HttpClient,
    private router: Router,
    private httpService: HttpService,
  ) {}

  login(credentials: { username: string; password: string }): Observable<any> {
    return this.http.post(`${this.intermediaryUrl}auth/login`, credentials, { headers: this.httpService.getHeaders() });
  }

  logout() {
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }
  isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    return token ? !this.jwtHelper.isTokenExpired(token) : false;
  }
  setToken(token: string) {
    localStorage.setItem('token', token);
  }
  rememberMe(isRemember: boolean) {
    localStorage.setItem('rememberMe', isRemember.toString());
  }
  getToken(): string | null {
    return localStorage.getItem('token');
  }
}