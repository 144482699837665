import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { AuthService } from '../services/auth.service';
import { ProfileService } from '../profile/profile.service';
import { ProfileDetails } from '../shared/interfaces/profileDetails-response.interface';

@Component({
  selector: 'app-root',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  title = '';
  loginDisplay = false;
  loginText = 'Login';
  private readonly _destroying$ = new Subject<void>();
  loginForm: FormGroup;
  returnUrl: string;
  error = '';
  rememberMe: boolean = false;
  userDetails: ProfileDetails;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    private profileService: ProfileService,
  ) {}

  ngOnInit(): void {
    if (this.isLoggedIn()) {
      this.router.navigate(['/dashboard']);
    }
    this.rememberMe = this.isRememberMe();
    this.initLoginForm();
  }

  initLoginForm() {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  login() {
    this.loginText = 'Please Wait ...';
    this.router.navigate(['/dashboard']);
    return true;
  }

  private isLoggedIn(): boolean {
    return !!localStorage.getItem('token');
  }

  get f() {
    return this.loginForm.controls;
  }

  // Login Api Live

  onSubmit() {
    if (this.loginForm.invalid) return;
    const { username, password } = this.loginForm.value;
    this.loginText = 'Please Wait ...';
    this.authService.login({ username, password }).subscribe(
      (response) => {
        this.authService.setToken(response?.access_token);
        this.authService.rememberMe(this.rememberMe);
        this.getUserProfileData();
        this.router.navigate(['/dashboard']);
      },
      (error) => {
        console.error(error);
        this.loginText = 'Login';
        Swal.fire({
          icon: 'error',
          text: error.error.message || error.error.message,
          showConfirmButton: true,
          confirmButtonText: 'Ok',
        });
      },
    );
  }

  isRememberMe(): boolean {
    const rememberMeValue = localStorage.getItem('rememberMe');
    if (rememberMeValue !== null) {
      return JSON.parse(rememberMeValue);
    }
    return false;
  }

  onRememberMeChange(event: any) {
    this.rememberMe = event.target.checked;
  }

  getUserProfileData() {
    this.profileService.getUserProfileData().subscribe(
      (response) => {
        this.userDetails = response;
        localStorage.setItem('userData', JSON.stringify(this.userDetails));
      },
      (error) => {
        console.error(error);
        Swal.fire({
          icon: 'error',
          text: error.error.message,
          showConfirmButton: true,
          confirmButtonText: 'Ok',
        });
      },
    );
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
    this.loginForm.reset();
  }
}