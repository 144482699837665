import { HttpService } from '../../../services/http.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { InvestmentsQueryParams } from '../../../shared/interfaces/investments-query-params';
import { InvestmentsResponseInterface } from '../../../shared/interfaces/investments-response.interface';
import { environment } from '../../../../environments/environment';
import { Summary } from '../../../shared/interfaces/bank-response.interface';
import { InvestmentsDistinctFilterValuesInterface } from '../interfaces/investments-distinct-filter-values.interface';
import { InvestorsResponseInterface } from 'src/app/shared/interfaces/Investors-response.interface';

@Injectable({ providedIn: 'root' })
export class InvestmentsHttpService extends HttpService {
  getInvestmentsFilterData(): Observable<InvestmentsDistinctFilterValuesInterface> {
    return this.http.get<InvestmentsDistinctFilterValuesInterface>(`${this.intermediaryBaseUrl}investments/distinct-filter-values`);
  }

  getInvestorsFilterData(): Observable<any> {
    return this.http.get<InvestorsResponseInterface>(`${this.intermediaryBaseUrl}investors/no-pagination`);
  }

  getCurrencyFilterData(): Observable<string[]> {
    return this.http.get<string[]>(`${this.baseUrl}currencies`);
  }

  getInvestmentsData(params: Partial<InvestmentsQueryParams>): Observable<InvestmentsResponseInterface> {
    const baseUrl = environment.url + 'investments';
    return this.http.get<InvestmentsResponseInterface>(baseUrl, {
      params: this.queryCleaner(params),
    });
  }

  getInvestmentsSummary(params: Partial<InvestmentsQueryParams>): Observable<Summary> {
    return this.http.get<Summary>(`${this.intermediaryBaseUrl}investments/summary`, {
      params: this.queryCleaner(params),
    });
  }
}
